import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NgZone } from '@angular/core';
import { resolveUrl } from '@backend/shared/http/base-url.utils';
import { environment } from '../../../../environments/environment';

export abstract class SignalRHubBaseBase<TRequest> {
  protected connection: HubConnection;

  protected lastRequest: TRequest;

  protected reconnectionDelay = 5000;

  protected _state = new BehaviorSubject(null);

  public get state$(): Observable<HubConnectionState> {
    return this._state.asObservable();
  }
  public get state(): HubConnectionState {
    return this.connection?.state ?? HubConnectionState.Disconnected;
  }

  protected constructor(
    connectionString: string,
    private ngZone: NgZone,
  ) {
    this.connection = new HubConnectionBuilder()
      .configureLogging(environment.settings.production ? LogLevel.Critical : LogLevel.Information)
      .withUrl(resolveUrl(connectionString), {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    this.connection.onreconnected(() => {
      this._state.next(HubConnectionState.Connected);
      if(this.lastRequest) {
        this.configure(this.lastRequest).then(() => {
          console.log('HUB Reconnected with last request');
        })
      }
    });
    this.connection.onclose(() => this._state.next(HubConnectionState.Disconnected));
  }

  public async connect(request?: TRequest, errorCallback?: () => void): Promise<any> {
    if (this.connection.state === HubConnectionState.Connected) {
      this.reconnect();
    }

    await this.connection.start().catch(() => {
      if (errorCallback) {
        errorCallback();
      } else return {};
    });
    this._state.next(HubConnectionState.Connected);
    await this.configure(request).catch(() => {});
  }

  public subscribe(request?: TRequest): Promise<any> {
    this.lastRequest = request;
    return this.connection.invoke('subscribe', request).catch((err) => {
      console.log(err);
      return 'error';
    });
  }

  public abstract setListeners(): void;

  public async configure(request?: TRequest): Promise<void> {
    this.setListeners();
    await this.subscribe(request).catch(() => {});
  }

  public reconnect(): void {
    return;
    let interval: NodeJS.Timeout = null;

    this.connection.onclose((error?: Error) => {
      if (!!error) {
        if (!interval) {
          this.ngZone.runOutsideAngular(() => {
            setInterval(
              () =>
                this.connection
                  .start()
                  .then(() => {
                    this._state.next(HubConnectionState.Connected);
                    this.configure(this.lastRequest)
                      .then(() => {
                        clearInterval(interval);
                        interval = null;
                      })
                      .catch((err) => {
                        throw err;
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  }),
              this.reconnectionDelay,
            );
          });
        }
      }
    });
  }

  public close(): Promise<void> {
    return this.connection.stop().catch((e) => console.log(e));
  }
}

export abstract class SignalRHubBase<TRequest, TResponse> extends SignalRHubBaseBase<TRequest> {
  protected updated$ = new Subject<TResponse>();

  protected deleted$ = new Subject<string>();

  public get updated(): Observable<TResponse> {
    return this.updated$.asObservable();
  }

  public get deleted(): Observable<string> {
    return this.deleted$.asObservable();
  }

  public get state(): HubConnectionState {
    return this.connection?.state ?? HubConnectionState.Disconnected;
  }

  protected constructor(connectionString: string, ngZone: NgZone) {
    super(connectionString, ngZone);
  }

  public close(): Promise<void> {
    return this.connection.stop().catch((e) => console.log(e));
  }
}
